<template>
  <p>Logout</p>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'PageLogout',
  data() {
    return {}
  },
  mounted() {
    this.logout().then(() => {
      this.$router.push('/auth/login')
    })
  },
  computed: {},
  methods: {
    ...mapActions(['logout'])
  }
}
</script>

<style lang="sass" scoped>
.page-login
  &__card
  max-width: 600px
  margin: 0 auto
</style>
